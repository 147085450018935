import 'antd/dist/antd.css';

import {
    Modal,
  Radio,
  Tabs,
  Menu,
  Input,
  Icon,
  message,
  Spin,
  Tooltip,
  Select,
  Button,
  Dropdown,
  Layout,
  ConfigProvider,
  Popover,
  Switch,
  Tree,
  Avatar,
  Comment,
  Card,
  Drawer,
  Row,
  Form,
  InputNumber,
  Col,
  Upload,
  Divider,
  Progress,
  Checkbox,
  Empty,
  List,
  Affix,
  Tag,
  DatePicker,
  notification
  } from 'antd';
export default {
  Modal,
  Radio,
  Tabs,
  Menu,
  Input,
  Icon,
  message,
  Spin,
  Tooltip,
  Select,
  Button,
  Dropdown,
  Layout,
  ConfigProvider,
  Popover,
  Switch,
  Tree,
  Avatar,
  Comment,
  Card,
  Drawer,
  Row,
  Form,
  InputNumber,
  Col,
  Upload,
  Divider,
  Progress,
  Checkbox,
  Empty,
  List,
  Affix,
  Tag,
  DatePicker,
  notification
}